import type { DOMWindow } from 'jsdom';
import { parseCookie } from 'static/cookies';

// Get some config variables from the browser environment.
export const getBrowserConfig = (params: {
    window: Window | DOMWindow;
}): {
    testGroups?: string;
    parentPageDomain: string;
    parentPageUrl: string;
} => {
    const href = params.window.location.href;
    const parentPageUrl = href;
    const url = new URL(href);
    const parentPageDomain = url.hostname;

    const cookie = params.window.document.cookie;
    const { cookieValue } = parseCookie(cookie);

    return {
        testGroups: cookieValue,
        parentPageDomain,
        parentPageUrl,
    };
};
