import { AccreditationsSchema } from 'common/enums/accreditations';
import { apiEndpointCodeSchema } from 'common/enums/api-endpoint-code';
import { DegreeSchema } from 'common/enums/degree';
import { ModalitySchema } from 'common/enums/modality';
import { PlacementTypeSchema } from 'common/enums/placement-type';
import { ProgramLengthSchema } from 'common/enums/program-length';
import { ProgramRequirementsSchema } from 'common/enums/program-requirements';
import { deserializeQueryString } from 'common/util/query-string';
import {
    booleanCoerceString,
    getBooleanFields,
    getNumberFields,
} from 'common/util/zod';
import type { DOMWindow } from 'jsdom';
import { commonElementConfigSchema } from 'static/frames/config/element-config';
import type { Logger } from 'static/logger';
import { z } from 'zod';
import { fromZodError } from 'zod-validation-error';

/*

Manages frame configuration parameters that are specified in the parent page's
query string (overriding parameters from the HTML element's data attribute).

We allow for a subset of the parameters we allow in an HTML element's config, and all parameters are optional.

We also prepend "ce_" to the query string parameters to avoid conflicts with other query string parameters the publisher might be using.

*/

type QsConfig = z.infer<typeof qsConfigSchema>;
const qsConfigSchema = z
    .object({
        // Params that can be set via an element, and overridden here via the
        // querystring.
        ce_accreditations: AccreditationsSchema,
        ce_advertiserDedupe: booleanCoerceString,
        ce_archeruserzip: z.string(),
        ce_degree: DegreeSchema,
        ce_fieldOfStudy: z.string(),
        ce_major: z.string(),
        ce_modality: ModalitySchema,
        ce_placementType: PlacementTypeSchema,
        ce_programLength: ProgramLengthSchema,
        ce_publisherSourceCode: z.string(),
        ce_publisherTrackingCode: z.string(),
        ce_requirements: ProgramRequirementsSchema,
        ce_test: booleanCoerceString,

        // Allows override of apiEndpoint
        ce_ep: apiEndpointCodeSchema.optional(),

        // Allow for setting of searchId in the querystring (it's not allowed in
        // an HTML element's config). This is important for launching new
        // browser tabs to show search results.
        ce_searchId: z.string(),
        ce_showSkeleton: booleanCoerceString,

        // Some aliases for publisher codes.
        ces: commonElementConfigSchema.shape.publisherSourceCode,
        cet: commonElementConfigSchema.shape.publisherTrackingCode,
    })
    .partial();

const parseQueryString = (params: { window: Window | DOMWindow }) => {
    const url = new URL(params.window.location.href);
    return deserializeQueryString({
        searchParams: url.searchParams,
        booleans: getBooleanFields(qsConfigSchema),
        numbers: getNumberFields(qsConfigSchema),
    });
};

// Build a partial ArcherConfig object from the parent's page querystring.
export const parseConfigFromQs = (params: {
    window: Window | DOMWindow;
    logger: Logger;
}): Omit<QsConfig, 'ces' | 'cet'> => {
    // Get a transformed version of the query string.
    const searchParams = parseQueryString({ window: params.window });

    // Parse against our schema. If there are any errors, warn about them and
    // return an empty object.
    const parseResult = qsConfigSchema.safeParse(searchParams);
    if (!parseResult.success) {
        const err = fromZodError(parseResult.error);
        params.logger.warn(`Unexpected ce_ query string params: ${err}`);
        return {};
    }

    // Handle 'ces' alias for ce_publisherSourceCode
    const publisherSourceCode =
        parseResult.data.ce_publisherSourceCode ?? parseResult.data.ces;
    delete parseResult.data.ces;

    // Handle 'cet' alias for ce_publisherTrackingCode
    const publisherTrackingCode =
        parseResult.data.ce_publisherTrackingCode ?? parseResult.data.cet;
    delete parseResult.data.cet;

    return {
        ...parseResult.data,
        ...(publisherSourceCode && {
            ce_publisherSourceCode: publisherSourceCode,
        }),
        ...(publisherTrackingCode && {
            ce_publisherTrackingCode: publisherTrackingCode,
        }),
    };
};
