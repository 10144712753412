import type { DOMWindow } from 'jsdom';

// Take a path and make sure it's fully qualified
export const toFullUrl = (params: {
    window: Window | DOMWindow;
    path: string;
}): URL => {
    if (
        params.path.startsWith('http://') ||
        params.path.startsWith('https://')
    ) {
        return new URL(params.path);
    } else {
        return new URL(
            `${params.window.location.protocol}//${
                params.window.location.host
            }${params.path.startsWith('/') ? '' : '/'}${params.path}`,
        );
    }
};
