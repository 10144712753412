import { assertUnreachable } from '@libraries/assert-unreachable';
import type { DomainEvent } from 'common/domain-events';
import { domainEventSchema, pageLoadedEventSchema } from 'common/domain-events';
import { handleAdClicked } from 'static/event-handler/ad-clicked';
import { handleAdLoaded } from 'static/event-handler/ad-loaded';
import { bubbleEvent } from 'static/event-handler/bubble-event';
import { handleCdoViewMoreClicked } from 'static/event-handler/cdo-view-more-clicked';
import { handleFormDropdownClosed } from 'static/event-handler/form-dropdown-closed';
import { handleFormDropdownOpened } from 'static/event-handler/form-dropdown-opened';
import { handleFormHeightChanged } from 'static/event-handler/form-height-changed';
import { handleFormLoaded } from 'static/event-handler/form-loaded';
import { handleFormSubmitted } from 'static/event-handler/form-submitted';
import { handlePageLoaded } from 'static/event-handler/page-loaded';
import { handleResultsHeightChanged } from 'static/event-handler/results-height-changed';
import { handleResultsLoaded } from 'static/event-handler/results-loaded';
import { handleSearchById } from 'static/event-handler/search-by-id';
import { handleSearchCompleted } from 'static/event-handler/search-completed';
import { handleSearchOnLoad } from 'static/event-handler/search-on-load';
import { handleSkeletonCleared } from 'static/event-handler/skeleton-cleared';

// Skip handling of events from other origins -- unless it's a page_loaded
// event, since the origin is the parent page.
const ignoreEvent = (params: {
    event: MessageEvent<unknown>;
    frameHost: string;
    domainEvent: DomainEvent;
}) => {
    if (pageLoadedEventSchema.safeParse(params.domainEvent).success)
        return false;

    return params.event.origin !== params.frameHost;
};

// Run appropriate handler for a domain event.
const runHandler = (event: DomainEvent) => {
    switch (event.eventName) {
        case 'ad_clicked':
            return handleAdClicked({ event });

        case 'ad_loaded':
            return handleAdLoaded({ event });

        case 'cdo_view_more_clicked':
            return handleCdoViewMoreClicked({ event });

        case 'form_dropdown_closed':
            return handleFormDropdownClosed({ event });

        case 'form_dropdown_opened':
            return handleFormDropdownOpened({ event });

        case 'form_height_changed':
            return handleFormHeightChanged({ event });

        case 'form_loaded':
            return handleFormLoaded({ event });

        case 'form_submitted':
            return handleFormSubmitted({ event });

        case 'navigating_to_external_results_location':
            return bubbleEvent({ event });

        case 'no_offers_found':
            return bubbleEvent({ event });

        case 'page_loaded':
            return handlePageLoaded({ event });

        case 'results_height_changed':
            return handleResultsHeightChanged({ event });

        case 'results_loaded':
            return handleResultsLoaded({ event });

        case 'search_by_id':
            return handleSearchById({ event });

        case 'search_completed':
            return handleSearchCompleted({ event });

        case 'search_on_load':
            return handleSearchOnLoad({ event });

        case 'skeleton_cleared':
            return handleSkeletonCleared({ event });

        case 'view_more_clicked':
            return bubbleEvent({ event });

        default:
            assertUnreachable(event);
    }
};

export const handleDomainEvent = (params: {
    event: MessageEvent<unknown>;
    frameHost: string;
    debug?: boolean;
}) => {
    // Parse the event and ignore it if it's not a domain event.
    const parseResult = domainEventSchema.safeParse(params.event.data);
    if (!parseResult.success) return;
    const domainEvent = parseResult.data;

    // Possibly ignore the event.
    if (ignoreEvent({ ...params, domainEvent })) return;

    if (params.debug) {
        console.log('Morpheus event:', JSON.stringify(domainEvent, null, 2));
    }

    return runHandler(domainEvent);
};
