import { configureCECookie } from 'static/cookies';
import { addEventListenerToParentPage } from 'static/event-listener';
import { addFramesToDOM, observeDOM } from 'static/frame-loaders';
import { addPrivacyPolicyToAllFrames } from 'static/frames/add-privacy-policies';
import { getHashConfig } from 'static/hash-config';
import { emitPageLoadedEvent } from 'static/page-loaded';
import { needsHostChange, setScriptHost } from 'static/set-script-host';
import { v4 as uuid } from 'uuid';

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        __ce_init_listings__: boolean;
    }
}

const transactionId = uuid();
const frameHost = new URL((document.currentScript as HTMLScriptElement).src)
    .origin;

function init() {
    const hashConfig = getHashConfig(window.location.hash);

    if (needsHostChange(hashConfig)) {
        setScriptHost(hashConfig);
        return;
    }

    window['__ce_init_listings__'] = true;

    addEventListenerToParentPage({ frameHost, debug: hashConfig.debug });

    configureCECookie();

    addFramesToDOM({
        frameHost,
        transactionId,
        hashConfig,
    });

    observeDOM({
        frameHost,
        transactionId,
        hashConfig,
    });

    addPrivacyPolicyToAllFrames();

    emitPageLoadedEvent();
}

if (window['__ce_init_listings__'])
    console.warn('you are trying to load the Archer script too many times');
else {
    init();
}
