export const COOKIE_NAME = 'ce-morph-test-group';

// it's important this cookie never has `httpOnly`, because we need to access it in the client
const COOKIE_CONFIG =
    'sameSite=None; secure; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT;';

// below is the definition for the cookie that will be used to store the users test group

// = is used to separate COOKIE_NAME from cookieValue
// ; is used to separate each COOKIE_NAME & cookieValue pair
// COOKIE_NAME=cookieValue;COOKIE_NAME=cookieValue;COOKIE_NAME=cookieValue;
// : is used to separate testName from testGroup
// / is used to separate each testName & testGroup pair
// testName:testGroup/testName:testGroup/testName:testGroup

// ce-morph-test-group=class-121-morpheus-dummy:1/imATest:33/anotherOne:111;

export type ActiveTest = (typeof activeTestNames)[number];
const activeTestNames = ['bsn-642-morpheus-skeleton-take3'] as const;

function generateTestGroupId() {
    // randomly choose a number between 1 - 100 to assign as the users group id for a particular test
    return Math.floor(Math.random() * 100) + 1;
}

function howMany(str: string, char: string) {
    let count = 0;

    for (let i = 0; i < str.length; i++) {
        if (str[i] === char) {
            count++;
        }
    }

    return count;
}

function trimArray<T>(arr: Array<T>): Array<T> {
    return arr.filter(
        (value): value is T =>
            value !== '' && value !== null && value !== undefined,
    );
}

export function getTestGroupPairs(testGroupString: string) {
    return trimArray(testGroupString.split('/'));
}
// this is a simple check to ensure the cookie is in the correct format
// a valid cookie would have one less / than :
const validateCookie = (cookieValue: string) => {
    const numSlashes = howMany(cookieValue, '/') + 1;
    const numColons = howMany(cookieValue, ':');

    if (numSlashes !== numColons) {
        setCookie('');
        return '';
    }

    return cookieValue;
};

export function parseCookie(cookie: string): {
    cookieValue?: string;
    testGroupPairs: Array<string>;
} {
    const currentCookieValue = cookie
        .split(`${COOKIE_NAME}=`)[1]
        ?.split(';')[0];
    if (!currentCookieValue)
        return { cookieValue: undefined, testGroupPairs: [] };

    const validatedCookieValue = validateCookie(currentCookieValue);

    const testGroupPairs = getTestGroupPairs(validatedCookieValue);

    return {
        cookieValue: validatedCookieValue,
        testGroupPairs,
    };
}

function setCookie(cookieValue: string) {
    document.cookie = `${COOKIE_NAME}=${cookieValue}; ${COOKIE_CONFIG}`;
}

// check for existing cookie
// if cookie exists, validate it's formatting - reset if needed
// set a new cookie using any existing testgroups, adding all missing testgroups
export const configureCECookie = (): void => {
    const cookie = document.cookie;
    const { cookieValue, testGroupPairs } = parseCookie(cookie);

    let newCookieValue = cookieValue || '';

    activeTestNames.forEach((testName) => {
        if (newCookieValue.includes(testName)) return;

        const testGroup = generateTestGroupId();
        testGroupPairs.push(`${testName}:${testGroup}`);
    });

    newCookieValue = testGroupPairs.join('/');

    setCookie(newCookieValue);
};
