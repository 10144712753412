import { handleDomainEvent } from 'static/event-handler';

export const addEventListenerToParentPage = (params: {
    frameHost: string;
    debug?: boolean;
}) => {
    const { frameHost, debug } = params;
    window.addEventListener('message', (event) =>
        handleDomainEvent({ event, frameHost, debug }),
    );
};
