import type { ResultsFrameParams } from 'common/frame-params';
import type { DOMWindow } from 'jsdom';
import { getBrowserConfig } from 'static/frames/config/browser-config';
import type { ResultsElementConfig } from 'static/frames/config/element-config';
import { resultsElementConfigSchema } from 'static/frames/config/element-config';
import { getPairKey } from 'static/frames/config/get-pair-key';
import { parseElementDataAttribute } from 'static/frames/config/parse-element-data-attribute';
import { parseConfigFromQs } from 'static/frames/config/qs-config';
import { insertFrame } from 'static/frames/insert-frame';
import { saveResultsFrame } from 'static/frames/loaded-frames';
import type { HashConfig } from 'static/hash-config';
import type { Logger } from 'static/logger';
import { fromZodError } from 'zod-validation-error';
import { getResultsFrameUrl } from './url';

/*

Configures and inserts an iframe for the /results route.

*/

let RESULT_KEY = 0;

// Parse and validate the element's config object in its "archerConfig" data
// attribute
const parseElementParams = (params: {
    element: HTMLElement;
}): ResultsElementConfig => {
    const elementConfig = parseElementDataAttribute(params.element);
    const parseResult = resultsElementConfigSchema.safeParse(elementConfig);
    if (parseResult.success) return parseResult.data;
    const msg = fromZodError(parseResult.error);
    throw new Error(`Invalid archerConfig results config: ${msg}`);
};

// Create the parameters to send to the iframe holding the /results route. By
// typing this function as ResultsFrameParams, we can ensure that we're passing
// the correct parameters to the iframe. c.f. app/routes/results.tsx
export const getResultsFrameParams = (params: {
    window: Window | DOMWindow;
    transactionId: string;
    element: HTMLElement;
    logger: Logger;
    resultKey: number;
    hashConfig: HashConfig;
}): ResultsFrameParams => {
    // Get config vars defined in the HTML element
    const elementConfig = parseElementParams(params);

    // Get config vars defined in the page's querystring
    const qsConfig = parseConfigFromQs({
        window: params.window,
        logger: params.logger,
    });

    // Get some extra config from the browser environment
    const browserConfig = getBrowserConfig({ window: params.window });

    return {
        accreditations:
            qsConfig.ce_accreditations ?? elementConfig.accreditations,
        advertiserDedupe:
            qsConfig.ce_advertiserDedupe ?? elementConfig.advertiserDedupe,
        degree: qsConfig.ce_degree ?? elementConfig.degree,
        ep: qsConfig.ce_ep,
        fieldOfStudy: qsConfig.ce_fieldOfStudy ?? elementConfig.fieldOfStudy,
        hideViewMorePrograms: elementConfig.hideViewMorePrograms,
        major: qsConfig.ce_major ?? elementConfig.major,
        modality: qsConfig.ce_modality ?? elementConfig.modality,
        pairKey: getPairKey({
            pairKey: elementConfig.pairKey,
            logger: params.logger,
        }),
        parentPageDomain: browserConfig.parentPageDomain,
        parentPageUrl: browserConfig.parentPageUrl,
        placementType: qsConfig.ce_placementType ?? elementConfig.placementType,
        programLength: qsConfig.ce_programLength ?? elementConfig.programLength,
        publisherSourceCode:
            qsConfig.ce_publisherSourceCode ??
            elementConfig.publisherSourceCode,
        publisherTrackingCode:
            qsConfig.ce_publisherTrackingCode ??
            elementConfig.publisherTrackingCode,
        requirements: qsConfig.ce_requirements ?? elementConfig.requirements,
        resultKey: params.resultKey,
        resultsLoadRange: elementConfig.resultsLoadRange,
        resultsPerPage: elementConfig.resultsPerPage,
        searchId: qsConfig.ce_searchId,
        searchOnLoad: elementConfig.searchOnLoad,
        showSkeleton: qsConfig.ce_showSkeleton,
        showPrivacyPolicy: false,
        test: qsConfig.ce_test ?? elementConfig.test,
        testGroups: browserConfig.testGroups,
        themeId: params.hashConfig.themeId ?? elementConfig.themeId,
        transactionId: params.transactionId,
        zipCode: qsConfig.ce_archeruserzip ?? elementConfig.archeruserzip,
    };
};

export const initResultsFrame = (params: {
    frameHost: string;
    transactionId: string;
    element: HTMLElement;
    hashConfig: HashConfig;
}): void => {
    const resultKey = RESULT_KEY++;

    const frameParams = getResultsFrameParams({
        window,
        transactionId: params.transactionId,
        element: params.element,
        logger: console,
        resultKey,
        hashConfig: params.hashConfig,
    });

    const url = getResultsFrameUrl({
        frameHost: params.frameHost,
        frameParams,
    });

    const frame = insertFrame({
        url,
        parentEl: params.element,
        position: 'relative',
        height: '0px',
    });

    saveResultsFrame({
        pairKey: frameParams.pairKey,
        transactionId: frameParams.transactionId,
        frame,
        searchOnLoad: Boolean(frameParams.searchOnLoad),
        resultKey,
        logger: console,
    });

    params.element.style.overflow = 'hidden';
    params.element.style.width = '100%';
};
